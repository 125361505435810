import * as React from 'react'
import { getPatientAddress } from 'utils/patientInfoApi'
import { customerQuery } from 'graphql/queries'
import { useQuery } from '@apollo/client'
import { useAddressStore } from '../state'
import { useQualifyFieldsStore } from 'stores/qualifyFieldsStore'
import { reportToSentry } from 'utils/reportToSentry'
import { capitalizeWords } from 'utils/utils'
import { Address } from '../types'
import { fetchAddressValidation, mapFootnotes } from '../components/utils'
import { serializeFromVerifiedAddress, serializeToOriginalAddress } from '../serializers'
import AobInvoice from 'types/aobInvoice'

/* This component syncs our AddressBlock with the customer's address in the database.
This is useful for when we have the customer's address saved in the database, but we want to use the autocomplete api to verify the address.
This component will fetch the customer's address on mount and set the address in state.
This will also set the current view to `complete` if the customer's address is found in the database.
We also sync the customer's first and last name from the qualify form to the address block.
*/

export const convertAddressToStandardCase = ( address: Address ) : Address => {
  const convertAddressToStandardCase = {
    ...address
  } as Address

  Object.keys( convertAddressToStandardCase ).forEach( key => {
    ( convertAddressToStandardCase as Record<string, any> )[key] = capitalizeWords( ( convertAddressToStandardCase as Record<string, any> )[key] )
  })

  return convertAddressToStandardCase
}

export default function useExternalSync({
  shouldSkipMountFetch = false,
  validateOnMount = true,
  setInitialAddress,
  disableCheckbox,
  invoice
} : { shouldSkipMountFetch: boolean, disableCheckbox: boolean, validateOnMount?: boolean, setInitialAddress: React.Dispatch<React.SetStateAction<null | Address>>, invoice?: AobInvoice }) {

  const [ initialLoading, setInitialLoading ] = React.useState<boolean>( true )
  const {
    setCurrentView,
    address,
    setAddress,
    setSmartyValidationMessage,
    setAddressWebConfirmed,
    setVerifiedAddress,
    setAddressPatientConfirmed,
    setIsAddressComplete,
    setAddressConfirmed,
    setHideAddressConfirm,
    hideAddressConfirm
  } = useAddressStore()

  const { formFields } = useQualifyFieldsStore()
  const { data: customerData } = useQuery( customerQuery, {
    skip: shouldSkipMountFetch
  })

  const initialValidation = ( address: Address ) => {
    if ( validateOnMount ) {
      fetchAddressValidation( serializeToOriginalAddress( address ) )
        .then( ( response ) => {
          if ( response ) {
            if ( response?.length === 0 ) {
              return setCurrentView( `confirm` )
            }

            const data = response[0]

            // get the usps verified address components
            if ( data?.components ) setVerifiedAddress( serializeFromVerifiedAddress( data?.delivery_line_1, data.components ) )

            // H# means we are expecting a street 2. Refer to https://www.smarty.com/docs/cloud/us-street-api for more info
            if ( data?.analysis?.footnotes?.includes( `H#` ) || data?.analysis?.footnotes?.includes( `S#` ) ) {
              setHideAddressConfirm( true )
              setAddressConfirmed( false )
              return setCurrentView( `confirm` ) // missing secondary address, send to confirm
            }

            const error = mapFootnotes( data.analysis.footnotes ?? `` )

            // AABB signifies a valid address and if we have a footnote that maps to an error we have setup send them to the verify step
            if ( data?.analysis?.dpv_footnotes !== `AABB` || ( data?.analysis?.footnotes && error ) ) {
              if ( data?.analysis?.footnotes ) setSmartyValidationMessage( mapFootnotes( data.analysis.footnotes ) )

              setHideAddressConfirm( true )
              setAddressConfirmed( false )
              return setCurrentView( `verify` )
            }
            setAddressWebConfirmed( true ) // This lets internal users know that the address was verified by the verification api
            setAddressPatientConfirmed( false )

            setIsAddressComplete( true )

            // have user confirm address if they have not already interacted with address confirmation
            if ( !disableCheckbox && !hideAddressConfirm ) setHideAddressConfirm( false )

            return setCurrentView( `complete` )
          }
          setCurrentView( `confirm` )
        })
        .catch( ( error ) => {
          setHideAddressConfirm( true )
          setAddressConfirmed( false )
          setCurrentView( `confirm` )

          reportToSentry( new Error( `Smarty USPS Validation Error`, {
            cause: error
          }), {
            address: JSON.stringify( address )
          })
        })
        .finally( () => { setInitialLoading( false ) })
    } else {
      setInitialLoading( false )
    }
  }

  React.useEffect( () => {

    let mounted = true
    // If we have the formFields from the qualify form, we import those if this is the first time the user is filling out the address block
    const missingAddressFields = Object.values( address ).some( value => !value )
    const qualifyFormComplete = Object.values( formFields ).some( value => value ) // Means we have some data from the qualify form

    const firstName = ( invoice?.first_name || formFields?.firstName || customerData?.customer?.firstname || address?.firstName ) ?? ``
    const lastName = ( invoice?.last_name || formFields?.lastName || customerData?.customer?.lastname || address?.lastName ) ?? ``

    if ( qualifyFormComplete && missingAddressFields ) {
      const newAddress = {
        ...address,
        firstName,
        lastName,
        state: formFields.state,
        zipCode: formFields.zipCode
      }
      setAddress( newAddress )
      setInitialAddress( newAddress )
      setInitialLoading( false )
      return setCurrentView( `edit` )
    }

    // skip mount fetch flag was given and we already have an address set in store so we don't need to fetch it
    if ( shouldSkipMountFetch || address.street ) {
      const newAddress = {
        ...address,
        state: address.state
      }
      setAddress( newAddress )
      setInitialAddress( newAddress )
      return initialValidation( newAddress )
    }

    // we need to fetch the users address
    getPatientAddress().then( _address => {

      if ( !mounted ) return
      const hasAddressSaved = _address?.street?.length
      if ( hasAddressSaved ) {
        const newAddress = {
          ..._address,
          firstName,
          lastName,
          zipCode: _address.zip,
          state: _address.state
        }
        setAddress( newAddress )
        setInitialAddress( newAddress )
        initialValidation( newAddress )
      } else {
        setInitialLoading( false )
        setHideAddressConfirm( true )
        setAddressConfirmed( false )
      }
      setCurrentView( hasAddressSaved ? `complete` : `edit`, false )
    })
      .catch( () => {
        setInitialLoading( false )
        if ( !disableCheckbox ) setHideAddressConfirm( false )
        setAddressConfirmed( false )
        reportToSentry( `AddressBlock2.0 - Failed to fetch address on mount ` )
      })
    return () => { mounted = false }
  }, [] )

  return {
    initialLoading
  }
}