// only edit if you have sign off -
// these colors have been agreed upon by stakeholders and we just want to make sure
// no one makes any ad hoc changes here without going thru the proper channels

module.exports = {
  black: `#010a16`,
  white: `#ffffff`,
  error: `#CE082A`,
  ctaYellow: `#FFD875`,
  sleepBlue: `#0093C9`,
  deepSleepBlue: `#004C97`,
  royalBlue: `#0057a8`,
  graphite: `#222222`,
  aeroflowNavy: `#003764`,
  hyperLinkBlue: `#91E2FF`, // when on a dark background
  inactiveGray: `#EBEBEB`,
  lightGray: `#F3F5F7`,
  inputGray: `#c2c2c2`,
  softGray: `#B5B5B5`,
  hardGray: `#9B9B9B`,
  success: `#2D9E2B`,
  darkGray: `#676C73`,
  secondaryBlue : {
    tint2: `#E3F2F9`,
    tint3: `#B8E1EF`,
    tint4: `#87CDE7`
  }
}