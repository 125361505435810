/* eslint-disable prefer-destructuring */
import { PhoneInput, EmailInput } from "."
import { useState } from "react"
import { useEmailInputState, usePhoneInputState } from "./useInputState"
export default function EmailPhoneGroup({initialShowEmail, initialShowPhone} : {initialShowEmail : boolean, initialShowPhone : boolean}) : JSX.Element {

  const setEmail = useEmailInputState()[1]
  const setPhone = usePhoneInputState()[1]

  const [ showEmailInput, setShowEmailInput ] = useState<boolean>( initialShowEmail )
  const [ showPhoneInput, setShowPhoneInput ] = useState<boolean>( initialShowPhone )

  const focusEmail = () => {
    setShowEmailInput( true )
    setShowPhoneInput( false )
    setPhone( `` )
  }

  const focusPhone = () => {
    setShowPhoneInput( true )
    setShowEmailInput( false )
    setEmail( `` )
  }

  return (
    <>
      <span onClick={() => { focusEmail() }}>
        <EmailInput
          textCurtain={!showEmailInput && `Use Email` || ``} required={false} onHandleRegisteredEmail={focusEmail}
          allowReadonly={false}
        />
      </span>

      <p className="p-lg font-bold my-4">{`OR`}</p>

      <span onClick={() => { focusPhone() }}>
        <PhoneInput textCurtain={!showPhoneInput && `Use Phone` || ``} required={false} allowReadonly={false} />
      </span>
    </>
  )
}