import React from 'react'
import { ImageTileTypes } from '../types'

export const ImageTile = ({ title, link, tileImage, altText } : ImageTileTypes ) : JSX.Element => {
  return (
    <a className="rounded-md block" href={link} target="_self">
      <div>
        <p className="sleep-gradient-lightToDark text-white px-2 py-1 sm:leading-5 sm:min-h-[48px] lg:min-h-0 content-center">
          {title}
        </p>
      </div>
      <div>
        <img src={tileImage} alt={altText} className="w-full" />
      </div>
    </a>
  )
}

export default ImageTile