import React, { ChangeEvent, SyntheticEvent, FocusEvent } from 'react'
import { useMutation } from '@apollo/client'
import { reportToSentry } from 'utils/reportToSentry'
import { requestPasswordResetEmailMutation } from 'graphql/mutations'
import { TextInput, Validators } from 'components/forms/components'
import LoadingSpinner from 'components/LoadingSpinner'
import { useEmailInputState } from 'components/auth/input/useInputState'

const RequestPasswordResetForm = ({ setError } : { setError : React.Dispatch<React.SetStateAction<string>> }) => {
  const [ email, setEmail ] = useEmailInputState()
  const [ requestResetPasswordEmail, { data, loading }] = useMutation( requestPasswordResetEmailMutation, {
    variables: {
      email
    },
    errorPolicy: `all`,
    onError: ( ( error ) => {
      reportToSentry( new Error ( `RequestPasswordResetForm -> requestPasswordResetEmail`, {
        cause: error
      }) )
    })
  })

  const handleSubmit = async ( event: SyntheticEvent ) => {
    event.preventDefault()
    if ( !Validators.email( email ) ) return setError( `Invalid email address` )
    const result = await requestResetPasswordEmail()

    if ( result?.errors ) {
      setError( result.errors.map( errorObject => { return errorObject.message }).join( ` ` ) )
    }

    if ( result?.data && !result.data.requestPasswordResetEmail ) {
      setError( `Either we couldn't find an account with that email, or that email is invalid` )
    }
  }

  const handleChange = ( event: ChangeEvent<HTMLInputElement> ) => {
    setEmail( event.currentTarget.value )
  }

  const onBlur = ( event: FocusEvent<HTMLInputElement> ) => {
    if ( !Validators.email( event.currentTarget.value ) ) {
      setError( `Please enter a valid email address` )
    }
  }

  if ( loading ) {
    return (
      <div className="my-8">
        <LoadingSpinner />
      </div>
    )
  }

  if ( data && data.requestPasswordResetEmail ) return <p className="my-3 font-light text-lg text-graphite text-center">{`You will receive an email with instructions to reset your password shortly`}</p>

  return (
    <form onSubmit={handleSubmit} className="my-5 flex flex-col w-full">
      <TextInput
        name="email"
        label="Email"
        type="email"
        value={email}
        onChange={handleChange}
        onBlur={onBlur}
        required
      />
      <br></br>
      <button className="btn btn-secondary mx-auto md:mx-0" type="submit">
        {`Reset My Password`}
      </button>
    </form>
  )
}

export default RequestPasswordResetForm
