import React, { ChangeEvent, InputHTMLAttributes, useEffect, useState, FocusEvent } from "react"
import { icoVisible, icoInvisible } from "resources/images"
import { Tooltip as ReactTooltip } from "react-tooltip"

import { getUniqueIdPrefix } from 'components/forms/utils'


export default function PasswordInput({
  name,
  value,
  required = false,
  label,
  className = `input`,
  errorClassName = `input-error`,
  labelClassName = `input-label`,
  errorMessageClassName = `text-error px-3`,
  asteriskClassName = `font-semibold text-error pl-2`,
  errorMessage = ``,
  formatter,
  validator,
  reference,
  onChange,
  onBlur,
  disabled = false,
  id,
  ...rest
} : {
  name: string;
  value: string;
  required?: boolean;
  label?: string;
  className?: string;
  errorClassName?: string;
  labelClassName?: string;
  errorMessageClassName?: string;
  asteriskClassName?: string;
  errorMessage?: string | boolean;
  formatter?: {
    function: ( value: string ) => string;
    onBlur?: boolean;
  };
  validator?: {
    function: ( value: string ) => boolean;
    failureMessage: string;
  };
  reference?: any;
  onChange: ( event: React.ChangeEvent<HTMLInputElement> ) => void;
  onBlur?: ( event: React.FocusEvent<HTMLInputElement> ) => void;
  disabled?: boolean;
  id?: string;
} & InputHTMLAttributes<HTMLInputElement> ) : JSX.Element {
  const [ showPassword, setShowPassword ] = useState( false )

  const [ validationError, setValidationError ] = useState( errorMessage )
  const uniqueId = id || `${getUniqueIdPrefix()}-${name}`

  useEffect( () => {
    setValidationError( errorMessage )
  }, [ errorMessage ] )

  const onHandleChange = ( event: ChangeEvent<HTMLInputElement> ) => {
    if ( formatter?.function && !formatter?.onBlur ) {
      event.currentTarget.value = formatter.function( event.currentTarget.value )
    }

    if ( validator && validator.function && !validator.function( event.currentTarget.value ) ) {
      setValidationError( validator.failureMessage || `Invalid Entry` )
    } else {
      setValidationError( `` )
    }

    onChange( event )
  }

  const onHandleBlur = ( event: FocusEvent<HTMLInputElement> ) => {
    if ( formatter?.function && formatter?.onBlur ) {
      event.currentTarget.value = formatter.function( event.currentTarget.value )
      onChange( event )
    }
    onBlur && onBlur( event )
  }

  // sets error, disabled, or normal className on input
  const inputClassName = ( validationError ) ? errorClassName : (
    ( disabled ) ? `input-disabled ${className}` : className
  )

  return (
    <>
      {label && (
        <label htmlFor={uniqueId} className={labelClassName}>
          {label}
          {required && <span className={asteriskClassName}>{`*`}</span>}
        </label>
      )}
      <div className="relative h-12">
        <input
          id={uniqueId}
          name={name}
          type={showPassword ? `text` : `password`}
          value={value}
          onChange={onHandleChange}
          className={inputClassName}
          required={required}
          ref={reference}
          disabled={disabled}
          onBlur={onHandleBlur}
          {...rest}
        />
        <div
          className="absolute cursor-pointer bottom-3.5 right-3"
          id="pw-tooltip"
          data-tooltip-content={`${showPassword ? `Hide` : `Show`} Password`}
        >
          <ReactTooltip
            anchorSelect="#pw-tooltip"
            place="top" offset={20}
          />
          <img
            src={showPassword ? icoInvisible : icoVisible} alt="show/hide password" height="24px"
            width="24px"
            className={`text-deepSleepBlue -mt-5`}
            onClick={() => { setShowPassword( !showPassword ) }}
          />
        </div>
      </div>
      {validationError && <p className={errorMessageClassName}>{validationError}</p>}
    </>
  )
}