import React from 'react'
import PropTypes from 'prop-types'
import Logo from './Logo'
import AccountMenu from '../accountMenuV2'

type MinimalHeaderBarProps = {
  showAccountMenu: boolean,
  showAccountLogout: boolean
}

const MinimalHeaderBar = ({ showAccountMenu, showAccountLogout } : MinimalHeaderBarProps ) => {

  return (
    <div className="w-full max-w-full bg-white pt-6 pb-4 flex flex-row items-center px-[60px] relative h-[102px]">
      <div className="w-12"></div>
      <div className="flex-grow flex flex-row items-center justify-center">
        <Logo withoutLink />
      </div>
      <div className="w-auto relative">{ ( showAccountMenu || showAccountLogout ) && <AccountMenu onlyShowLogout={showAccountLogout} />}</div>
    </div>
  )
}

MinimalHeaderBar.propTypes = {
  showAccountMenu: PropTypes.bool,
  showAccountLogout: PropTypes.bool
}

export default MinimalHeaderBar