import { useState } from 'react'
import styled from 'styled-components'
import tailwindColors from 'config/tailwindColors'
import { notInterestedLinks, THANK_YOU_PAGE_PATH } from 'modules/aobPayments/constants'
import { fetchSubmitNotInterestedReason } from 'modules/aobPayments/fetchPromises'
import { reportToSentry } from 'utils/reportToSentry'
import LoadingSpinner from 'components/LoadingSpinner'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'

function NotInterestedTooltip({setNotInterested}) {

  const [ submitLoading, setSubmitLoading ] = useState( false )
  const [ error, setError ] = useState( `` )

  const navigate = useNavigate()

  const handleSubmitNotInterested = ( reason ) => {
    const urlParams = new URLSearchParams( window.location.search )
    const patientHash = urlParams.get( `sgh` ) ?? urlParams.get( `txt` )

    setSubmitLoading( true )
    setError( `` )
    fetchSubmitNotInterestedReason( reason )
      .then ( ( data ) => {
        if ( data?.meta?.status !== `OK` || data?.error ) {
          throw new Error( `AOB Payments Portal Error: Error submitting not interested reason`, {
            cause: data
          })
        }
        setSubmitLoading( false )
        setNotInterested( true )
        navigate( patientHash ? `${THANK_YOU_PAGE_PATH}?sgh=${patientHash}` : THANK_YOU_PAGE_PATH )
      })
      .catch( error => {
        setSubmitLoading( false )
        setError( `We had a problem processing your submission. Please try again.` )
        reportToSentry( error )
      })

  }

  const NotInterestedTooltipLink = styled.p`
    text-decoration: underline;
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 10px;
    color: ${tailwindColors.deepSleepBlue};
    text-decoration-color: ${tailwindColors.deepSleepBlue};
    text-decoration-thickness: 1px;
    cursor: pointer;
  `

  return (
    <div className="m-auto">
      <h4 className="w-11/12 p-2 text-base tracking-wide text-black">{`I am no longer interested in receiving supplies through insurance`}</h4>
      {error && <p className="text-[red]">{ error }</p>}
      <div className="px-2">
        {
          submitLoading ?
            <div className="py-10">
              <LoadingSpinner />
            </div>
            :
            notInterestedLinks.map( ( link, i ) => {
              return (
                <NotInterestedTooltipLink
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  onClick={() => { return handleSubmitNotInterested( link ) }}
                >
                  {link}
                </NotInterestedTooltipLink>
              )
            })
        }
      </div>
    </div>
  )
}

NotInterestedTooltip.propTypes = {
  setNotInterested: PropTypes.func
}

export default NotInterestedTooltip