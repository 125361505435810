import React, { useEffect, useState } from 'react'
import { LoginForm } from 'components/auth'
import { preLoginLocationVar} from 'apollo'
import { useNavigate, useLocation } from 'react-router-dom'
import { MY_ACCOUNT_PATH } from 'routes'
import { useTokenStore } from 'stores/tokenStore'
import ErrorBanner from './ErrorBanner'
import OneTimeLoginForm from 'components/auth/OneTimeLoginForm'
import { PAYMENT_PORTAL_PATH } from 'modules/aobPayments/constants'

export default function Login() : JSX.Element {

  const [ error, setError ] = useState<string>( `` )

  const navigate = useNavigate()
  const location = useLocation()
  const { customerToken, setCustomerToken } = useTokenStore()

  useEffect( () => {
    const currentQueryParams = new URLSearchParams( window.location.search )

    // when coming from resupply we will have a m2 token and isResupply flag in the url
    // authenticate using the m2 token and then navigate to AOB where we will use the claimedItems data + patientId from the url to populate the invoice
    const { m2Token, isResupply, isMyAccount } =
            Object.fromEntries( new URLSearchParams( window.location.search ) )

    if ( m2Token ) {
      setCustomerToken( m2Token )

      // navigate to AOB with the original URL params or to my account
      if ( isResupply === `true` ) return navigate( `${PAYMENT_PORTAL_PATH}${window.location.search}` )
      if ( isMyAccount === `true` ) return navigate( MY_ACCOUNT_PATH )

    } else if ( customerToken ) return navigate( location?.state?.from ?? MY_ACCOUNT_PATH )


    // this code is to auto login stakeholders so they can remotely troubleshoot patient issues
    if ( currentQueryParams?.get( `sct` ) ) {
      setCustomerToken( currentQueryParams?.get( `sct` ) ?? `` )
      navigate( MY_ACCOUNT_PATH )
    }

    // Land on login page, we need to store the LoginState (from : {search,  pathname}),
    // for the redirect after a successful login
    if ( location?.state ) preLoginLocationVar( location.state )
  }, [] )

  return (
    <div className="relative">
      <ErrorBanner error={error} />
      <div className="max-w-2xl px-8 mx-auto flex flex-col items-center justify-center md:items-start my-10">
        <h1 className="text-[35px] text-center md:text-left">{`Log In`}</h1>
        <OneTimeLoginForm setError={setError} />
        <br />
        <div className="w-full flex justify-between items-center gap-4">
          <div className="sleep-gradient-lightToDark w-full h-0.5" /> <span className="font-semibold text-lg">{`OR`}</span> <div className="sleep-gradient-darkToLight w-full h-0.5" />
        </div>
        <br />
        <LoginForm setError={setError} />
        <br />
      </div>
    </div>
  )
}