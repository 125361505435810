import { TextInput } from 'components/forms/components'
import { useEmailInputState, useValidInputs } from './useInputState'
import { regexErrors } from 'components/forms/constants'
import { useEffect, useRef, useState } from 'react'

interface EmailInputProps {
  required?: boolean | undefined;
  textCurtain?: string;
  onHandleRegisteredEmail?: () => void;
  allowReadonly?: boolean;
}

export default function EmailHOC({ required = false, textCurtain, onHandleRegisteredEmail, allowReadonly = true } : EmailInputProps ) : JSX.Element {
  const [ email, setEmail ] = useEmailInputState()
  const { isValidEmail } = useValidInputs()
  const [ hasFocus, setHasFocus ] = useState<boolean>( false )
  const [ readOnly, setReadOnly ] = useState<boolean>( Boolean( email?.length ) )
  const [ touched, setTouched ] = useState<boolean>( false )
  const inputRef = useRef<HTMLInputElement>()
  const firstCall = useRef<boolean>( true )
  const urlParams = new URLSearchParams( location.search )
  const decodedRegisteredEmailUrlParam = decodeURIComponent( urlParams.get( `registeredUser` ) ?? `` )

  useEffect( () => {
    // If the parent opens the textCurtain, we want to reset and add focus to the input
    if ( !textCurtain && !readOnly ) {
      setTouched( false )

      // If the parent passes a registered email, we want to set it in the input
      if ( decodedRegisteredEmailUrlParam ) {
        setEmail( decodedRegisteredEmailUrlParam )
        onHandleRegisteredEmail?.()
      } else {
        setEmail( `` )
      }

      inputRef?.current?.focus()
    }
    firstCall.current = false
  }, [ textCurtain ] )

  function handleFocus() {
    setHasFocus( !hasFocus )
  }

  if ( textCurtain ) { // Hides the input behind an underline-text
    return (
      <p className="underline cursor-pointer text-deepSleepBlue">
        {textCurtain}
      </p>
    )
  }

  if ( readOnly && Boolean( email?.length ) && !touched && allowReadonly ) {
    return (
      <>
        <p className="p font-bold ml-1 mt-1">{`Email`}</p>
        <p className="ml-4 mt-2 mb-4">{email} <span className="cursor-pointer text-deepSleepBlue font-semibold ml-1" onClick={() => { setReadOnly( false ) }}>{`change`}</span></p>
      </>
    )
  }

  return (
    <TextInput
      id="email"
      type="email"
      label="Email"
      name="email"
      value={email}
      onChange={( e: React.ChangeEvent<HTMLInputElement> ) => { setTouched( true ); setEmail( e.currentTarget.value ) }}
      autoComplete="username"
      className="px-3 mt-2 mb-5 input"
      errorMessage={( !hasFocus && !isValidEmail && touched ) ? regexErrors.email : ``}
      onBlur={handleFocus}
      onFocus={handleFocus}
      required={required}
      formatter={undefined} reference={inputRef} validator={undefined}
    />
  )
}