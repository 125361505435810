import { RequestPasswordResetForm } from 'components/auth'
import ErrorBanner from './ErrorBanner'
import { useState } from 'react'

const RequestPasswordResetScreen = () => {
  const [ error, setError ] = useState<string>( `` )
  return (
    <div className="relative">
      <ErrorBanner error={error} />
      <div className="max-w-2xl px-8 mx-auto flex flex-col items-center md:items-start justify-center my-10">
        <h1 className="text-[35px] text-center">{`Forgot Your Password?`}</h1>
        <p className="text-graphite text-center my-5 font-light text-lg">{`Please enter your email address below to receive a password reset link.`}</p>
        <RequestPasswordResetForm setError={setError} />
      </div>
    </div>

  )
}

export default RequestPasswordResetScreen
