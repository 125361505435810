import React, { useState } from 'react'
import { formatMoney, splitMoney } from 'utils/money'
import { PaymentMethod } from 'components/payments'
import { IPaymentMethod } from 'components/payments/types'
import { emptyCardMethod } from 'modules/aobPayments/constants'
import HeadingWithBackButton from '../backButton/Heading'
import { useGetAobSegmentedCopy } from 'modules/aobPayments/hooks/useGetAobSegmentedCopy'

export type PaymentMethodType = {
    cardNumber: string;
    expMonth: string;
    expYear: string;
    ccv: string;
    cardHolderFirstName: string;
    cardHolderLastName: string;
    city: string;
    state: string;
    zip: string;
    address: string;
}

type UpfrontPaymentBlockProps = {
  splitSinglePayment: boolean | null;
  setSplitSinglePayment: React.Dispatch<React.SetStateAction<boolean | null>>;
  invoice: {
    total_patient_owes: number;
  };
  selected?: string | null;
  setSinglePaymentMethod: React.Dispatch<React.SetStateAction<IPaymentMethod>>;
  setDisableSubmitPayment: React.Dispatch<React.SetStateAction<boolean>>;
  singlePaymentMethod: IPaymentMethod;
}

function UpfrontPaymentBlock({ splitSinglePayment, setSplitSinglePayment, invoice, singlePaymentMethod, setSinglePaymentMethod, setDisableSubmitPayment }: UpfrontPaymentBlockProps ) : JSX.Element {

  const [ displayPaymentMethods, setDisplayPaymentMethods ] = useState( false )
  const [ payMethod, setPayMethod ] = useState<IPaymentMethod>( singlePaymentMethod )
  const [ showHeading, setShowHeading ] = useState( true )

  const segmentedUpfrontPaymentCopy = useGetAobSegmentedCopy()

  const handleEdit = () => {
    setDisableSubmitPayment( true )
  }

  const handleSubmit = () => {
    setDisableSubmitPayment( false )
    setSinglePaymentMethod( payMethod )
  }

  const handleSplitPaymentsSelection = ( split: boolean ) => {
    setSplitSinglePayment( split )
    setDisplayPaymentMethods( true )
  }

  const handleSetShowHeading = ( showHeading: boolean ) => {
    setShowHeading( showHeading )
  }

  const upfront = invoice.total_patient_owes?.toFixed( 2 )

  if ( !displayPaymentMethods ) {
    return (
      <div className="max-w-2xl mx-auto">
        <div className="flex flex-col items-center w-full max-w-md mx-auto gap-3">
          <p className="text-xl tracking-wide font-medium text-center text-graphite">{`Upfront Payment Method`}</p>
          {segmentedUpfrontPaymentCopy?.UpfrontPaymentDetails &&
            <segmentedUpfrontPaymentCopy.UpfrontPaymentDetails />
          }
          <p className="sm:text-sm lg:text-base text-graphite text-center ">{`Choose One`}</p>
          <button
            className="btn-primary mb-10"
            onClick={() => { handleSplitPaymentsSelection( false ) }}
            data-testid="aob_one_time_payment"
          >
            <p className="text-lg">{`One time payment of ${upfront}`}</p>
          </button>
          <p>{`OR`}</p>
          <button
            className="btn-primary mt-7"
            onClick={() => { handleSplitPaymentsSelection( true ) }}
            data-testid="aob_split_payments"
          >
            <p className="text-lg">{`3 monthly payments of ${formatMoney( splitMoney( invoice.total_patient_owes, 3 ) )}`}</p>
          </button>
          <p className="-mt-3 sm:text-sm lg:text-base">{`($${upfront} over 3 months)`}</p>
        </div>
      </div>
    )
  }

  return (
    <div className="flex flex-col items-center justify-center mb-5">

      {showHeading &&
        <HeadingWithBackButton
          headingText={`${splitSinglePayment ? `Split` : `Full` } Upfront Payment Method`}
          headingClassName="text-xl tracking-wide font-medium text-center text-graphite"
          onBackClick={() => setDisplayPaymentMethods( false )}
          backText="Change"
        />
      }

      <PaymentMethod
        payMethod={payMethod}
        setPayMethod={setPayMethod}
        onSetPaymentMethod={handleSetShowHeading}
        handleRemove={() => {
          setPayMethod( Object.assign({}, emptyCardMethod ) )
          setSinglePaymentMethod( Object.assign({}, emptyCardMethod ) )
        }}
        onSave={handleSubmit}
        onEdit={handleEdit}
        splitSinglePayment={splitSinglePayment}
        onBackToUpfrontPayment={() => setDisplayPaymentMethods( false )}
      />
    </div>
  )
}

export default UpfrontPaymentBlock