import React from 'react'
import PropTypes from 'prop-types'
import { useAccountMenuIsActive } from 'apollo'
import { useLocation, Link } from 'react-router-dom'
import { useTokenStore } from 'stores/tokenStore'
import cn from 'classnames'

import AccountMenuButton from './AccountMenuButton'
import AccountMenuDropdown from './AccountMenuDropdown'

const AccountMenu = ({ onlyShowLogout }) => {
  const [ isActive, setIsActive ] = useAccountMenuIsActive()
  const mediaQueryList = React.useMemo( () => { return window.matchMedia( `(max-width: 767px)` ) }, [] )
  const [ isMobile, setIsMobile ] = React.useState( mediaQueryList.matches )
  const accountMenuRef = React.useRef( null )
  const location = useLocation()
  const isPaymentsRoute = location.pathname.includes( `payments` )
  const { customerToken } = useTokenStore()

  // only show account menu if user is logged in
  const toggleMenuIsActive = ( e ) => {
    if ( customerToken ) {
      if ( e.type === `click` ) {
        setIsActive( !isActive )
      } else {
        setIsActive( e.type === `mouseenter` )
      }
    }
  }

  React.useEffect( () => {
    const handleClickOutside = () => {
      setIsActive( false )
    }

    const handleMediaQueryChange = event => {
      setIsMobile( event.matches )
    }

    document.addEventListener( `click`, handleClickOutside )
    mediaQueryList.addListener( handleMediaQueryChange )

    return () => {
      document.removeEventListener( `click`, handleClickOutside )
      mediaQueryList.removeListener( handleMediaQueryChange )
    }
  }, [ ] )

  const accountMenuActive = () => {
    return Boolean( isActive ) || Boolean( isActive && onlyShowLogout )
  }

  return (
    <div
      className={cn ( `sm:w-auto md:w-[122px] min-w-[60px]`, {
        'sm:basis-16 md:basis-56': !customerToken,
        'md:pt-16': customerToken && isActive
      })}
    >
      <div
        ref={accountMenuRef}
        className={cn ( `z-50 cursor-pointer bg-transparent`, {
          'absolute right-0 top-1/2 -translate-y-1/2': isActive && isMobile
        })}
        onMouseLeave={toggleMenuIsActive}
        onMouseEnter={toggleMenuIsActive}
        onClick={toggleMenuIsActive}
      >
        { !isPaymentsRoute &&
          <>
            <div
              className={cn({
                'p-3 relative border border-[#E4E4E4] rounded-lg bg-white': isActive && !isMobile,
                'sm:pl-0 md:p-3': customerToken && ( !isActive || isMobile )
              })}
            >
              <AccountMenuButton isMobile={isMobile} toggleMenuIsActive={toggleMenuIsActive} menuIsActive={accountMenuActive()} />
              <AccountMenuDropdown isMobile={isMobile} onlyShowLogout={onlyShowLogout} menuIsActive={accountMenuActive()} />
            </div>
            { !customerToken &&
              <Link to="/login" >
                <button type="button" onClick={toggleMenuIsActive} className="btn-primary flex-shrink sm:text-xs md:text-xl sm:w-16 md:w-full sm:h-5 md:h-10 sm:p-0">
                  {`Log in`}
                </button>
              </Link>}
          </>
        }
      </div>
    </div>
  )
}

AccountMenu.propTypes = {
  onlyShowLogout: PropTypes.bool
}

export default AccountMenu
