import React from 'react'
import { errorIconLogin } from 'resources/images'

function ErrorBanner({ error }: { error: string }) : JSX.Element | null {
  if ( !error ) return null

  return (
    <div className="sticky py-4 px-6 top-0 w-full bg-error text-base md:text-lg text-white flex md:justify-center items-center font-light z-50 gap-2">
      <img src={errorIconLogin} alt="login error icon" className="w-6 h-6 mr-2" />
      { error }
    </div>
  )
}

export default ErrorBanner