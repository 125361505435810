import React from 'react'
import RadioInput from 'components/forms/components/RadioInput'
import { useHealthTapStore } from '../../State/healthTapStore'
import { healthTapLogoImg } from 'resources/images'

export const HealthTapBlock = () => {
  const { healthtapSelected, setHealthtapSelected } = useHealthTapStore()

  const handleHealthTapSelected = ( value: boolean ) => {
    setHealthtapSelected( value )
  }

  const checkBoxContainerStyle = `bg-[#F3F5F7] p-5 my-3 rounded-md w-full cursor-pointer`
  const radioBtnStyle = `themed-radio--showCheck inline-flex shrink-0 -translate-y-[-4px]`

  return (
    <div className="flex flex-col items-center justify-center">
      <h2 className="font-semibold font-inter text-[#0057A8] sm:text-3xl lg:text-[42px] pb-4">{`Prescription Required`}</h2>
      <p className="text-lg">{`Choose one`}<span className="text-error">{`*`}</span></p>
      <>
        {/* checked - redirect to healthtap at end of snl */}
        <div
          className={checkBoxContainerStyle}
          onClick={() => { handleHealthTapSelected( true ) }}
          data-testid="snl_healthtap"
        >
          <RadioInput
            name="healthtapSelected"
            label="Get a Prescription Online with"
            labelClassName="!text-lg text-black font-medium"
            containerClassName="flex"
            className={radioBtnStyle}
            checked={healthtapSelected === true}
          >
            <span className="inline-block transform -translate-y-[-4px]"> {/* align logo with text */}
              <img
                src={healthTapLogoImg}
                alt="HealthTap"
                aria-hidden="false"
                className="ml-1"
              />
            </span>
          </RadioInput>
          <div className="text-graphite pl-[30px]">
            <p className="sm:text-sm lg:text-base">
              <span className="font-semibold">{`Once you have placed your order, you will be directed to HealthTap to schedule an online video visit with a doctor licensed in your state. `}</span>
              {`HealthTap makes it easy and convenient to get your CPAP resupply prescriptions in a compliant way.`}
            </p>
            <p className="sm:text-xs lg:text-sm italic pt-6">
              {` I understand that Aeroflow Sleep will share my relevant information with HealthTap for treatment purposes.`}
            </p>
          </div>
        </div>

        {/* checked - continue with normal snl flow */}
        <div
          className={checkBoxContainerStyle}
          onClick={() => { handleHealthTapSelected( false ) }}
          data-testid="snl_doctor"
        >
          <RadioInput
            name="healthtapSelected"
            label="Contact my doctor"
            labelClassName="!text-lg text-black font-medium"
            className={radioBtnStyle}
            checked={healthtapSelected === false}
          />
          <div className="text-graphite pl-[30px]">
            <p className="sm:text-sm lg:text-base">{`We will send a request to your doctor, listed above, to approve your healthcare supplies.`}</p>
          </div>
        </div>
      </>
    </div>
  )
}