import confetti, { CreateTypes } from "canvas-confetti"
import { useRef } from "react"

export function useTriggerSleepConfetti() {
  const instance = useRef<undefined | CreateTypes>()

  const onInit = ({ confetti } : { confetti: CreateTypes }) => {
    instance.current = confetti
  }

  const ZZZ = confetti.shapeFromText({
    text: `💤`,
    scalar: 4
  })

  const commonOptions = {
    colors: [ `FFE400`, `FFBD00`, `E89400`, `FFCA6C`, `FDFFB8` ],
    shapes: [ ZZZ ],
    spread: 180,
    scalar: 4,
    particleCount: 120,
    angle: 90,
    gravity: 1
  }

  const triggerZs = () => {
    if ( instance.current ) {
      instance.current({
        ...commonOptions
      })
    }

  }

  return {
    instance,
    onInit,
    triggerZs
  }
}