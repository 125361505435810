import { reportToSentry } from 'utils/reportToSentry'
import { identifyPaymentMethodType } from 'components/payments/types.d'
import { addMagentoEnvAuthHeaders } from 'utils/magentoEnvAuthHeaders'
import { getAuthHeader } from 'utils/auth/helpers'

export const chargeV2 = async ( paymentBody ) => {

  const isSubscription = Boolean( paymentBody?.totalOccurrences )
  const requestOperation = isSubscription ? `recurring-charge` : `charge`
  const auth = getAuthHeader()

  const response = await fetch( `${process.env.REACT_APP_LAMBDA_AUTHNET_PAYMENTS_URL}/v2/${requestOperation}`, {
    method: `POST`,
    headers: {
      ...addMagentoEnvAuthHeaders(),
      Authorization: auth
    },
    body: JSON.stringify( paymentBody )
  })
    .catch( error => {
      reportToSentry( new Error( `Failed to make payment`, {
        cause: error
      }), {
        details: paymentBody
      })
    })

  if ( !response || !response.ok ) throw new Error( `Failed to make payment` )

  const paymentData = await response.json()

  const authnet_message = paymentData?.messages?.message[0]?.text ?? ``

  if ( authnet_message !== `Successful.` ) {
    console.error( authnet_message )
    reportToSentry( new Error( `Failed to make payment` ), {
      authnet_message,
      authnet_response: JSON.stringify( paymentData ?? `Authnet Response is undefined` )
    })
  }

  return authnet_message
}

export const getPaymentProfiles = async ( signal ) => {
  const auth = getAuthHeader()

  const response = await fetch( `${process.env.REACT_APP_LAMBDA_AUTHNET_PAYMENTS_URL}/v2/profile`, {
    method: `get`,
    headers: {
      ...addMagentoEnvAuthHeaders(),
      'Authorization': auth,
      'Content-Type': `application/json`,
      'Access-Control-Allow-Origin': `*`
    },
    signal
  })

  return await response.json()
}

export const fetchTermsAndConditions = async ( signal ) => {
  const response = await fetch( `${process.env.REACT_APP_LAMBDA_ACCEPTANCE_OF_BENEFITS_URL}?profit_center_pk=5`, {
    signal
  })

  return await response.json()
}

export const submitEmergencyContactInfo = async ( emergenctContactFormFields ) => {
  const auth = getAuthHeader()

  const response = await fetch( `${process.env.REACT_APP_LAMBDA_PATIENTS_URL}/emergency-contact`, {
    mode: `cors`,
    method: `POST`,
    headers: {
      ...addMagentoEnvAuthHeaders(),
      Authorization: auth
    },
    body: JSON.stringify( emergenctContactFormFields )
  })

  return await response.json()
}

export const submitAobAcceptance = async () => {
  const auth = getAuthHeader()

  const response = await fetch( `${process.env.REACT_APP_LAMBDA_ACCEPTANCE_OF_BENEFITS_URL}/accept-from-browser`, {
    mode: `cors`,
    method: `POST`,
    headers: {
      ...addMagentoEnvAuthHeaders(),
      Authorization: auth
    },
    body: JSON.stringify({
      profit_center_pk: 5
    })
  })

  return await response.json()
}

export const fetchPatientInvoice = async ( patientHash, signal ) => {
  const response = await fetch ( `${process.env.REACT_APP_AOB_URL}/invoice/${patientHash}`, {
    signal: signal
  })

  return await response.json()
}

export const fetchSubmitNotInterestedReason = async ( reason ) => {
  const response = await fetch( `${process.env.REACT_APP_SNL_URL}/new-leads/not-interested`, {
    method: `POST`,
    headers: {
      "Authorization": getAuthHeader()
    },
    body: JSON.stringify({
      not_interested_reason: reason,
      source: `${window.location.hostname}`
    })
  })
  return await response.json()
}

export const submitPaymentMethod = async ( paymentMethod ) => {
  const auth = getAuthHeader()
  const paymentType = identifyPaymentMethodType( paymentMethod.paymentData )

  const getNames = ( fullName ) => {
    const names = fullName.split( ` ` )
    if ( names.length === 1 ) return {
      firstName: names[0]
    }
    if ( names.length > 1 ) return { // we have multiple names, going to take the first and add all other names to last
      firstName: names[0],
      lastName: names.slice( 1 ).join( ` ` )
    }

    return {}
  }

  if ( !paymentType ) throw new Error( `Invalid paymentMethod passed` )
  const payment = ( paymentType === `card` ) ? {
    cardNumber: ( paymentMethod?.paymentData?.cardNumber ?? `` ).replace( /-/g, `` ),
    expirationDate: `20${paymentMethod.paymentData.expYear}-${paymentMethod.paymentData.expMonth}`,
    cardCode: paymentMethod.paymentData.ccv,
    firstName: paymentMethod.paymentData.cardholderFirstName,
    lastName: paymentMethod.paymentData.cardholderLastName,
    address: paymentMethod.paymentData.address,
    city: paymentMethod.paymentData.city,
    state: paymentMethod.paymentData.state,
    zip: paymentMethod.paymentData.zip
  } : {
    routingNumber: paymentMethod.paymentData.routingNumber,
    accountNumber: paymentMethod.paymentData.accountNumber,
    nameOnAccount: paymentMethod.paymentData.nameOnAccount,
    ...( getNames( paymentMethod.paymentData.nameOnAccount ) )
  }

  const response = await fetch( `${process.env.REACT_APP_LAMBDA_AUTHNET_PAYMENTS_URL}/v2/payment-profile`, {
    method: `POST`,
    headers: {
      ...addMagentoEnvAuthHeaders(),
      Authorization: auth
    },
    body: JSON.stringify( payment )
  })

  return await response.json()
}

export const postEstimate = async ( body ) => {
  const response = await fetch( `${process.env.REACT_APP_AOB_URL}/estimator`, {
    method: `POST`,
    headers: {
      "Content-Type": `application/json`
    },
    body: JSON.stringify( body )
  })

  return await response.json()
}

export const getEstimate = async ( batchId ) => {
  const response = await fetch ( `${process.env.REACT_APP_AOB_URL}/estimator/${batchId}`, {
    headers: {
      "Content-Type": `application/json`
    }
  })

  return await response.json()
}

export const updateInvoice = async ( removedItems, patientHash ) => {
  const response = await fetch( `${process.env.REACT_APP_AOB_URL}/invoice/${patientHash}`, {
    method: `PUT`,
    headers: {
      "Content-Type": `application/json`
    },
    body: JSON.stringify({
      dme_ids: removedItems
    })
  })

  return await response.json()
}

export const finalizeInvoice = async ( patientHash ) => {
  const response = await fetch( `${process.env.REACT_APP_AOB_URL}/invoice/${patientHash}/done`, {
    method: `POST`,
    headers: {
      "Content-Type": `application/json`
    }
  })

  const data = await response.json()

  if ( data?.errors && data.errors?.length && data.errors[0]?.message ) {
    reportToSentry( `AOB 2.0 CST: Error finalizing patient invoice`, {
      patientHash,
      message: data.errors[0].message
    })
  } else if ( data?.data?.success !== true ) {
    reportToSentry( `AOB 2.0 CST: Error finalizing patient invoice`, {
      patientHash
    })
  }
}

export const fetchBalance = async ( ) => {
  const response = await fetch( `${process.env.REACT_APP_AOB_URL}/balance-available`, {
    method: `GET`,
    headers: {
      "Content-Type": `application/json`,
      Authorization: getAuthHeader(),
      ...addMagentoEnvAuthHeaders()
    }
  })
    .catch( error => {
      reportToSentry( new Error( `Error fetching bill for patient`, {
        cause: error
      }) )
    })

  if ( ( response?.errors && response.errors?.length && response.errors[0]?.message ) ) {
    reportToSentry( `Error fetching bill for patient`, {
      message: response.errors[0].message
    })
  }

  return await response.json()
}