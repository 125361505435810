import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'components/modal'
import InsuranceEdit from './InsuranceEdit'
import { reportToSentry } from 'utils/reportToSentry'
import updateInsurance from './api'
import { useInsurancePayers } from 'hooks/Insurance'
import { useInsuranceInfoState, useResupplySetting } from 'modules/resupply2'
import { useAccountStore } from '../../../modules/accountV2/state'
import { useInsuranceModalRemoteStore } from 'stores/modalStore'
import { refreshTrackerDataStore } from 'stores'

export default function InsuranceBlock({
  payer,
  payerType,
  regions,
  useModal,
  setPrimaryCleared,
  myAccount = false,
  initialEditView = false,
  isRemoteModalOpen
}) {
  const [ insuranceInfo, setInsuranceInfo ] = useInsuranceInfoState()
  const [ resupplySetting, setResupplySetting ] = useResupplySetting()
  const { customer } = useAccountStore()
  const patientId = customer.data.patient_id
  const { setIsRemoteModalOpen } = useInsuranceModalRemoteStore()
  const { refreshTrackerData } = refreshTrackerDataStore.getState()

  const [ formFields, setFormFields ] = useState({
    regionCode: ``,
    payerFriendlyName: ``,
    memberID: ``
  })
  const [ isNewView, setIsNewView ] = useState( !payer?.name )

  const payerData = useInsurancePayers( formFields?.regionCode )

  const [ isEditView, setIsEditView ] = useState( initialEditView )

  const primaryUpdateRequired = resupplySetting.updatePrimaryPayerStatus === `Required`
  const secondaryUpdateRequired = resupplySetting.updateSecondaryPayerStatus === `Required`

  useEffect( () => {
    setIsNewView( !payer?.name )
    setIsEditView( payer?.name && initialEditView )
  }, [ payer ] )

  function toggleEdit( formFields, payerName ) {
    if ( isEditView && formFields && payerName ) {
      setFormFields( formFields )
      updateInsurancePayer( payerName, formFields.memberID )
    }

    if ( isRemoteModalOpen ) return setIsRemoteModalOpen( false )

    setIsEditView( !isEditView )
  }

  const handleChange = event => {
    if ( event.currentTarget.name === `regionCode` ) {
      setFormFields({
        regionCode: event.currentTarget.value,
        parentPayerPK: ``,
        memberID: ``
      })
    } else {
      setFormFields({
        ...formFields,
        [event.currentTarget.name]: event.currentTarget.value
      })
    }
  }

  const updateInsurancePayer = ( name, hic ) => {
    setInsuranceInfo( Object.assign({}, insuranceInfo, {
      [payerType.toLowerCase()]: {
        ...insuranceInfo[payerType.toLowerCase()],
        name,
        hic
      }
    }) )
  }

  const handleSubmit = async () => {
    const foundPayer = payerData.payers.find( p => {
      return p.friendly_name === formFields.payerFriendlyName
    })

    const payerTypeUpperCase = payerType.toUpperCase()

    const use_new = ( ( payerTypeUpperCase === `PRIMARY` && primaryUpdateRequired ) || ( payerTypeUpperCase === `SECONDARY` && secondaryUpdateRequired ) ) &&
      window.location.pathname !== `/account/insurance`

    const response = await updateInsurance( use_new, patientId ?? resupplySetting.patientId, {
      insurance_type: payerTypeUpperCase,
      parent_payer_pk: parseInt( foundPayer.parent_payer_pk ),
      member_id: formFields.memberID
    }
    ).catch( error => {
      return reportToSentry(
        new Error( `Resupply: Error Updating Insurance`, {
          cause: error
        }),
        {
          error,
          insuranceInfo: JSON.stringify( formFields )
        }
      )
    })

    if ( response?.meta?.status === `OK` ) {
      setIsNewView( false )
      // initial edit view is based on if the payer update is required
      if ( payerTypeUpperCase === `PRIMARY` ) {
        setResupplySetting(
          Object.assign({}, resupplySetting, {
            updatePrimaryPayerStatus: false
          })
        )
      }
      if ( payerTypeUpperCase === `SECONDARY` && initialEditView ) {
        setResupplySetting(
          Object.assign({}, resupplySetting, {
            updateSecondaryPayerStatus: false
          })
        )
      }

      updateInsurancePayer( formFields.payerFriendlyName, formFields.memberID )
      refreshTrackerData() // refresh the account tracker data
    }

    return response
  }

  const clearInsurance = () => {
    updateInsurancePayer( ``, `` )
    setIsEditView( false )
    if ( setPrimaryCleared ) setPrimaryCleared( true )
  }

  // No Insurance
  if ( !isEditView && isNewView ) {
    return (
      <div className={`max-w-sm bg-${ myAccount ? `white` : `lightGray` } p-4 mb-10`} >
        <p>{`No ${payerType} insurance on file`}</p>
        <p
          className="font-thin text-deepSleepBlue underline cursor-pointer"
          onClick={() => { return toggleEdit() }}
        >
          {`Add ${payerType} Insurance (If Applicable)`}
        </p>
      </div>
    )
  }

  if ( !useModal && ( isEditView || isNewView ) ) {
    return (
      <div className="bg-lightGray p-4 max-w-sm mb-10">
        <InsuranceEdit
          formFields={formFields}
          onChange={handleChange}
          onSubmit={handleSubmit}
          onClose={toggleEdit}
          regions={regions}
          payerData={payerData}
        />
      </div>
    )
  }

  // Insurance view
  return (
    <>
      {/* --- Edit --- */}
      {useModal && (
        <Modal
          isOpen={isEditView || isRemoteModalOpen}
          onClose={() => { return toggleEdit() }}
          defaultCloseButton={false}
          closeOnFocusChange={false}
        >
          <div className="flex flex-col items-center justify-center">
            <p className="font-bold text-deepSleepBlue">{`Current ${payerType} Insurance`}</p>
            {payer?.name && <p className={myAccount ? `` : `font-bold`}>{payer?.name}</p>}
            <p className="font-thin">{`Member # ${payer?.hic ?? ``}`}</p>
          </div>
          <InsuranceEdit
            formFields={formFields}
            onChange={handleChange}
            onSubmit={handleSubmit}
            onClose={toggleEdit}
            regions={regions}
            payerData={payerData}
          />
          <div className="max-w-xs w-full mx-auto mt-3">
            <button
              className="btn-primary"
              onClick={() => { return toggleEdit() }}
            >
              {`Close`}
            </button>
          </div>
          {payerType === `Secondary` &&
            <p
              className="a text-center mt-10"
              onClick={clearInsurance}
            >
              {`I no longer have this insurance${myAccount ? `, remove from my account` : `` }`}
            </p>
          }
        </Modal>
      )}
      {/* --- View --- */}
      <div className={`max-w-sm ${!myAccount ? `mb-10` : ``}`}>
        <div className={`bg-${myAccount ? `white` : `lightGray`} p-4`}>
          {
            myAccount ?
              <div className="flex gap-3">
                {payerType && <p className="font-bold">{`${payerType} Insurance`}</p>}
                <p className="text-deepSleepBlue underline cursor-pointer" onClick={() => { return toggleEdit() }}>
                  {`Edit`}
                </p>
              </div>
              :
              <>
                {payerType && <p className="font-light">{`${payerType} Insurance`}</p>}
              </>
          }
          {payer?.name && <p className={myAccount ? `` : `font-bold`}>{payer?.name}</p>}
          <p className="font-thin">{`Member # ${payer?.hic ?? ``}`}</p>
          {
            !myAccount &&
            <p className="font-thin text-deepSleepBlue underline cursor-pointer" onClick={() => { return toggleEdit() }}>{`Edit Insurance Information`}</p>
          }
        </div>
      </div>
    </>
  )
}

InsuranceBlock.propTypes = {
  payer: PropTypes.object,
  payerType: PropTypes.string.isRequired,
  regions: PropTypes.array.isRequired,
  patientRegion: PropTypes.object,
  useModal: PropTypes.bool,
  initialEditView: PropTypes.bool,
  setPrimaryCleared: PropTypes.func,
  patientId: PropTypes.number,
  myAccount: PropTypes.bool,
  isRemoteModalOpen: PropTypes.bool
}
