import { useAddressStore } from '../state'
import { reportToSentry } from 'utils/reportToSentry'
import { serializeToPatientsUpdateAddress } from '../serializers'
import { useState } from 'react'
import { addMagentoEnvAuthHeaders } from 'utils/magentoEnvAuthHeaders'
import { getAuthHeader } from 'utils/auth/helpers'

export function useUpdateAddress() {
  const { address, setIsAddressComplete, setHideAddressConfirm, setAddressConfirmed } = useAddressStore()

  const [ isUpdatingPatientAddress, setIsUpdatingPatientAddress ] = useState<boolean>( false )

  const updatePatientAddress = ( addressPatientConfirmed = false, addressWebConfirmed = false, updatedAddress = address ) => {
    setIsUpdatingPatientAddress( true )
    const authorization = getAuthHeader()
    setHideAddressConfirm( true )
    setAddressConfirmed( true )

    fetch( `${process.env.REACT_APP_LAMBDA_PATIENTS_URL}/update-address`, {
      method: `POST`,
      headers: {
        ...addMagentoEnvAuthHeaders(),
        'Authorization': authorization
      },
      body: JSON.stringify({
        addressType: `shipping`,
        addressWebConfirmed: addressWebConfirmed,
        addressPatientConfirmed: addressPatientConfirmed,
        ...serializeToPatientsUpdateAddress( updatedAddress )
      })
    })
      .then( async ( response ) => {
        const responseData = await response.json()
        if ( responseData && responseData?.meta?.status?.toLowerCase() === `ok` ) {
          return setIsAddressComplete( true )
        }
      })
      .catch( err => {
        console.error( `Something happened updating patient address` )
        reportToSentry( new Error( `Something happened updating patient address` ), {
          err
        })

        setIsAddressComplete( false )
      })
      .finally( () => setIsUpdatingPatientAddress( false ) )
  }

  return {
    updatePatientAddress,
    isUpdatingPatientAddress
  }
}