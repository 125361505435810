import AddressP from "../components/AddressP"
import { useUpdateAddress } from "../hooks/useUpdateAddress"
import { useAddressStore } from "../state"
import { useAddressStyleStore } from "../style"

export default function ConfirmAddress({ onSuccessfulConfirm } : { onSuccessfulConfirm?: () => void; }) : JSX.Element {
  const { address, setCurrentView, setAddressPatientConfirmed, setIsAddressComplete, setHideAddressConfirm, setAddressConfirmed } = useAddressStore()

  const { styleOverrides } = useAddressStyleStore()
  const { updatePatientAddress } = useUpdateAddress( )

  const { ctaStyle } = styleOverrides

  const handleConfirm = () => {
    setIsAddressComplete( true )
    setAddressPatientConfirmed( true )
    updatePatientAddress( true, false )
    setAddressConfirmed( true )
    setHideAddressConfirm( true )
    return onSuccessfulConfirm ? onSuccessfulConfirm() : setCurrentView( `complete` )
  }

  return (
    <div>
      <p className="text-base text-center mt-5 w-full bg-[#E90C0C] text-white py-2 rounded-t-md">{`Address Cannot Be Validated`}</p>
      <div className="p-3 rounded-xl flex flex-col items-center gap-6 max-w-md mx-auto mt-5">
        <p className="font-light">{`We were not able to verify your address, which could lead to delivery delays or lost packages. Your address entered may be missing apartment, building or lot number.`}</p>
        <p className="font-light">{`Do you want to edit this address or continue with your originally entered address?`}</p>
        <AddressP address={address} className="text-center text-deepSleepBlue font-semibold" />
        <button className="btn btn-primary" onClick={() => setCurrentView( `edit` )}>{`Edit`}</button>
        <p className={ctaStyle} onClick={handleConfirm}>{`Continue`}</p>
      </div>
    </div>

  )
}