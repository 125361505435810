import { getAuthHeader } from 'utils/auth/helpers'
import { addMagentoEnvAuthHeaders } from 'utils/magentoEnvAuthHeaders'
import { reportToSentry } from 'utils/reportToSentry'
import { getProductPricing } from 'utils/patientInfoApi'

export const fetchSnlData = async ( route, signal ) => {
  const response = await fetch( process.env.REACT_APP_SNL_URL + route, {
    signal
  })

  if ( response?.ok ) return await response.json()
  throw new Error( `Response Failed with ${response?.status}` )
}

export const submitSleepNewLead = async ( submitData, headers, formCode, processId ) => {

  const passHeaders = !formCode || !processId
  const ENDPOINT = `${process.env.REACT_APP_SNL_URL}/new-leads/order`
  const url = !passHeaders ? `${ENDPOINT}/${formCode}/${processId}` : ENDPOINT

  const response = await fetch( url, {
    method: `POST`,
    body: JSON.stringify( submitData ),
    ...(
      passHeaders && {
        headers
      }
    )
  })

  return response.json()

}

export const getHashFromLeadInfo = async ( formCode, processId ) => {

  if ( !processId || !formCode ) {
    const response = await fetch( `${process.env.REACT_APP_SNL_URL}/new-leads/options`, {
      headers: {
        ...addMagentoEnvAuthHeaders(),
        "Authorization": getAuthHeader()
      }
    })

    return await response.json()
  }

  if ( processId && formCode ){
    const response = await fetch( `${process.env.REACT_APP_SNL_URL}/new-leads/options/${formCode}/${processId}` )

    return response.json()
  }
}

export const fetchSubmitNotInterestedReason = async ( reason ) => {
  const response = await fetch( `${process.env.REACT_APP_SNL_URL}/new-leads/not-interested`, {
    mode: `cors`,
    method: `POST`,
    headers: {
      ...addMagentoEnvAuthHeaders(),
      "Authorization": getAuthHeader()
    },
    body: JSON.stringify({
      notInterestedReason: reason
    })
  })

  return await response.json()
}

export const fetchPatientRouting = async ( signal ) => {
  const response = await fetch( `${process.env.REACT_APP_SNL_URL}/patient-routing`, {
    headers: {
      ...addMagentoEnvAuthHeaders(),
      "Authorization": getAuthHeader()
    },
    signal
  })

  return await response.json()
}

export const fetchPatientCheck = async ( email_address, dob ) => {
  const response = await fetch( `${process.env.REACT_APP_SNL_URL}/new-leads/patient-check`, {
    mode: `cors`,
    method: `POST`,
    headers: {
      ...addMagentoEnvAuthHeaders(),
      "Authorization": getAuthHeader()
    },
    body: JSON.stringify({
      email_address,
      dob
    })
  })

  return await response.json()
}

// If a user exists in internal but not in magento, trigger an email to be sent that allows them to set up their account
export const triggerResetPasswordEmail = async ( email_address, dob ) => {
  const response = await fetch( `${process.env.REACT_APP_SNL_URL}/new-leads/send-qualify-form-patient-email`, {
    mode: `cors`,
    method: `POST`,
    headers: {
      ...addMagentoEnvAuthHeaders(),
      "Authorization": getAuthHeader()
    },
    body: JSON.stringify({
      email_address,
      dob
    })
  })

  return await response.json()
}


export const setupSessionTracking = ( hash, email ) => {
  getProductPricing( true ).then( response => {
    const patientId = response?.data?.patient?.patient_id
    if ( patientId ) {
      fetch( `${process.env.REACT_APP_SNL_URL}/new-leads/user-actions`, {
        method: `POST`,
        headers: {
          "Authorization": `PatientHash ${hash}`,
          ...addMagentoEnvAuthHeaders()
        },
        body: JSON.stringify({
          patientEmail: email,
          patientId
        })
      })
        .catch( error => {
          reportToSentry( new Error( `Error: POST Session Tracker`, {
            cause: error
          }), {
            hash,
            email,
            patientId
          })
        })
    }
  })

}

export const updateSessionTracking = ( authorization, sapCompleted = null, acCustomerClaimed = null ) => {
  getProductPricing().then( response => {
    const patientId = response?.data?.patient?.patient_id
    if ( patientId ) {
      fetch( `${process.env.REACT_APP_SNL_URL}/new-leads/user-actions/${patientId}`, {
        method: `PATCH`,
        headers: {
          "Authorization": authorization,
          ...addMagentoEnvAuthHeaders()
        },
        body: JSON.stringify({
          ...( sapCompleted ? {
            sapCompleted
          } : {}),
          ...( acCustomerClaimed ? {
            acCustomerClaimed
          } : {})
        })
      })
        .catch( error => {
          reportToSentry( new Error( `Error: POST Session Tracker`, {
            cause: error
          }), {
            authorization,
            patientId
          })
        })
    }
  })

}