import React from 'react'
import { CreateAccountForm } from 'components/auth'
import LoadingSpinner from 'components/LoadingSpinner'
import {
  patientEmailFromAppServer,
  patientIdFromAppServer,
  patientPhoneFromAppServer,
  preLoginLocationVar
} from 'apollo'
import { Navigate } from 'react-router-dom'
import { LOGIN_PATH } from 'routes'
import { useReactiveVar } from '@apollo/client'
import { Validators } from '../../components/forms/components'
import { getProductPricing } from 'utils/patientInfoApi'

const ResupplyGateway = ({ ...pageProps }) => {

  const [ loading, setLoading ] = React.useState( true )
  const [ sleepHash, setSleepHash ] = React.useState( `` )
  const [ patientNeedsMagentoAccount, setPatientNeedsMagentoAccount ] = React.useState( false )
  const [ eventHash, setEventHash ] = React.useState( `` )
  const [ isFromCompleteRegistration, setIsFromCompleteRegistration ] = React.useState( null )
  const [ patientData, setPatientData ] = React.useState({
    first_name: ``,
    last_name: ``,
    dob: ``,
    email_address: ``
  })

  React.useEffect( () => {
    /*
      check if user needs to complete account registration
      this would indicate a user who exists in internal sql but does not have a magento account
    */

    // check for hash and completeRegistration search params
    const queryParams = new URLSearchParams( window.location.search )
    const hashFromUrl = queryParams.get( `sgh` )

    setIsFromCompleteRegistration( queryParams.has( `completeRegistration` ) && Boolean( hashFromUrl ) )
    setSleepHash( hashFromUrl )

    if ( !hashFromUrl ) return setLoading( false )

    // get create account form values from product-pricing
    getProductPricing().then( ( res ) => {
      const patientData = res?.data?.patient || {}
      const { first_name, last_name, dob, email_address } = patientData

      setPatientData( ( ( prev ) => ({
        ...prev,
        first_name,
        last_name,
        dob,
        email_address
      }) ) )

      setLoading( false )
    })
  }, [ ] )

  const from = useReactiveVar( preLoginLocationVar )
  // to help bridge the delay between m2 account create and internal stamping the pt row on the m2 customer we provide a fallback here
  // the fallback is used on the resupply/claims component to fetch supply options if the m2 customer query doesn't yet have a patient_id
  React.useEffect( () => {
    const isFromSearch = from?.search

    const fromPath = isFromSearch ? from?.pathname : ``

    const getSgh = ( ) => {
      const searchQs = fromPath?.search

      const urlParsed = searchQs ? new URLSearchParams( searchQs ) : null

      if ( urlParsed ) {
        const eventHash = urlParsed.get( `sgh` ) || urlParsed.get( `txt` )
        if ( eventHash ) return eventHash

        return null
      }

      return null

    }

    const aobEventHash = ( fromPath && ( fromPath === `/payments/pay` ) ) ? getSgh() : null

    if ( aobEventHash ){
      setEventHash( aobEventHash )
    }

  }, [] )

  React.useEffect( () => {
    if ( pageProps.patientData?.patient_id ){
      patientIdFromAppServer( pageProps.patientData.patient_id )
    }
    if ( pageProps.patientData?.phone ){
      patientPhoneFromAppServer( pageProps.patientData.phone )
    }
    if ( pageProps.patientData?.email_address ){
      patientEmailFromAppServer( pageProps.patientData.email_address )
    }
  }, [ pageProps.patientData ] )

  React.useEffect( () => {

    let mounted = true
    const cleanUpFunction = () => { mounted = false }

    // @NOTE for a user to get to this page an mgh query param is created on a login sequence prior to this view
    // The user somehow found their way here without a valid sleep hash (UUID), send them to login
    if ( isFromCompleteRegistration === false && ( !pageProps.patientData?.unique_hash || !Validators.UUID( pageProps.patientData?.unique_hash ) ) && !eventHash ) window.location.href = `https://aeroflowsleep.com/login`
    if ( !pageProps?.patientData?.magento_customer_id ) setPatientNeedsMagentoAccount( true )

    if ( isFromCompleteRegistration === false && mounted && !sleepHash ) setSleepHash( pageProps?.patientData?.unique_hash )

    setLoading( false )

    return cleanUpFunction
  }, [ isFromCompleteRegistration ] )

  if ( loading ) {
    return (
      <div className="my-8">
        <LoadingSpinner />
      </div>
    )
  }

  return (
    <div className="max-w-2xl px-8 mx-auto flex flex-col items-center justify-center my-10">
      <h3 className="sm:text-2xl md:text-4xl font-bold mb-4 text-center">{patientNeedsMagentoAccount ? `Create Account`: `Customer Login`}</h3>

      {!patientNeedsMagentoAccount && (
        <div className="mt-4 mb-2">
          <p className="text-graphite text-center my-5 font-light text-lg">{`Registered Customers`}</p>
        </div>
      )}

      <div>
        {patientNeedsMagentoAccount
          ? (
            <>
              {( !pageProps?.patientData || !pageProps?.patientData?.email_address ) &&
              <>
                <p className="text-graphite text-center my-5 font-light text-lg">{`We’re excited to support you on your journey to better sleep! Creating an account helps us provide you with the best care possible.`}</p>
              </>
              }
              {( pageProps?.patientData && pageProps?.patientData?.email_address ) &&
                <p className="text-graphite text-center my-5 font-light text-lg">{`Let’s set up your new Aeroflow Sleep account. Please set a password below. Moving forward, this will be your password to log in to your account and place your supply orders.`}</p>
              }
              <CreateAccountForm
                firstName={patientData?.first_name || pageProps.patientData?.first_name}
                lastName={patientData?.last_name || pageProps.patientData?.last_name}
                email={patientData?.email_address || pageProps.patientData?.email_address}
                dateOfBirth={patientData?.dob || pageProps.patientData?.date_of_birth}
                sleepHash={sleepHash}
              />
            </>
          )
          : <Navigate to={`${LOGIN_PATH}`} replace />
        }
      </div>
    </div>
  )
}

export default ResupplyGateway
