import * as React from 'react'
import { Formatters, TextInput } from 'components/forms/components'
import { Validate } from '../validation'
import EditUnavailableDialogue from '../components/EmailUnavailableDialogue'
import { isEmailAvailableQuery } from 'graphql/queries'
import { useLazyQuery } from '@apollo/client'
import { reportToSentry } from 'utils/reportToSentry'
import LoadingSpinner from 'components/LoadingSpinner'
import { putPartialLead } from '../api'
import PropTypes from 'prop-types'
import { setCookies } from 'utils/cookie'
import { useQualifyFieldsStore } from 'stores/qualifyFieldsStore'
import { useQualifyFormStore } from '../state'
import { fetchPatientCheck, triggerResetPasswordEmail } from '../../sleepNewLeads/fetch'

export default function Qualify({ isSiteOffline, isEmbedded, ...pageProps}) {

  const { formFields, formErrors, setFormError, setFormFields } = useQualifyFieldsStore()
  const { emailNotAvailable, setEmailNotAvailable } = useQualifyFormStore()
  const { internalAccountExists, setInternalAccountExists } = useQualifyFormStore()
  const [ loading, setLoading ] = React.useState( false )
  const [ stateFormFields, setStateFormFields ] = React.useState({
    username: ``,
    dobMonth: ``,
    dobDay: ``,
    dobYear: ``
  })

  const nextPage = () => {
    setFormFields({
      ...formFields,
      ...stateFormFields
    })
    pageProps.nextPage()
  }

  const [ checkEmailAvailability ] = useLazyQuery(
    isEmailAvailableQuery, {
      variables: {
        email: stateFormFields.username
      },
      onError: ( ( error ) => {
        reportToSentry( new Error ( `isEmailAvailableQuery -> Qualify`, {
          cause: error
        }) )
      })
    })

  React.useEffect( () => {
    setCookies()
  }, [] )


  const inputRefs = {
    username: React.useRef( null ),
    dobMonth: React.useRef( null ),
    dobDay: React.useRef( null ),
    dobYear: React.useRef( null )
  }

  const handleChange = ( event ) => {
    const { name, value } = event.target

    setFormError( name, `` )

    // Only allow numeric input for DOB fields
    if ( name.startsWith( `dob` ) && !( /^\d*$/ ).test( value ) ) {
      return
    }

    const errorMessage = Validate( name, value )

    // when dobDay, dobMonth length is two, tab to the next dob input
    if ( value.toString().length === 2 && !errorMessage ) {
      if ( name === `dobMonth` ) inputRefs.dobDay.current.focus()
      if ( name === `dobDay` ) inputRefs.dobYear.current.focus()
    }

    setStateFormFields( ( prevFields ) => ({
      ...prevFields,
      [name] : value
    }) )
  }

  const handleNext = ( event ) => {
    event.preventDefault()

    setLoading( true )
    const firstInputWithError = Object.keys( inputRefs ).find( name => {
      const errorMessage = Validate( name, stateFormFields[name] )
      setFormError( name, errorMessage ) // Update error messages for the untouched case of ''

      return Boolean( errorMessage )
    })

    Object.keys( inputRefs ).forEach( name => {
      const errorMessage = Validate( name, stateFormFields[name] )
      setFormError( name, errorMessage ) // Update error messages for the untouched case of ''
    })

    if ( firstInputWithError ) {
      setLoading( false )
      return inputRefs[firstInputWithError].current.focus()
    }

    // if site is offline we want to bypass email check
    if ( isSiteOffline ) {
      setLoading( false )
      return nextPage()
    }

    checkEmailAvailability().then( ( result ) => {
      if ( result?.data?.isEmailAvailable.is_email_available ) {
        // check that patient doesn't exist in internal
        fetchPatientCheck( stateFormFields.username, `${stateFormFields.dobYear}-${stateFormFields.dobMonth}-${stateFormFields.dobDay}` )
          .then( data => {
            // if patient already exists in internal
            if ( data.meta.status === `OK` && data.data.existing_patient === true ) {
              // trigger email for user to reset password and direct to existing user page
              triggerResetPasswordEmail( stateFormFields.username, `${stateFormFields.dobYear}-${stateFormFields.dobMonth}-${stateFormFields.dobDay}` )
                .then( () => {
                  setEmailNotAvailable( true )
                  setInternalAccountExists( true )
                  setLoading( false )
                })
                .catch( error => {
                  // user might exist yet, but email trigger failed so send to next step
                  setEmailNotAvailable( false )
                  setLoading( false )
                  putPartialLead( stateFormFields.username )
                  nextPage()
                  reportToSentry( `triggerResetPasswordEmail failed`, {
                    username: stateFormFields.username,
                    cause: error
                  })
                })
            } else {
              // user does not exist yet, proceed to next steps
              setEmailNotAvailable( false )
              setLoading( false )
              putPartialLead( stateFormFields.username )
              nextPage()
            }
          })
          .catch( err => {
            setLoading( false )
            reportToSentry( `fetchPatientCheck failed`, {
              username: stateFormFields.username,
              cause: err
            })
          })
      } else {
        setEmailNotAvailable( true )
        setLoading( false )
      }
    })
      .catch( error => {
        setLoading( false )
        reportToSentry( `Error Verifying Email`, {
          username: stateFormFields?.username,
          errorMessage: JSON.stringify( error ?? `` )
        })
      })
  }

  const handleBlur = ( event ) => {
    const { name, value } = event.target

    setFormError( name, `` )

    const errorMessage = Validate( name, value )

    if ( errorMessage ) setFormError( name, errorMessage )
  }

  const dobClassNames = {
    className:`input text-center !py-2 !px-2`,
    errorClassName:`input-error text-center !py-2 !px-2`,
    errorMessageClassName:`text-error text-center text-sm`
  }

  const labelClassNames = `sm:font-medium md:font-normal sm:text-sm md:text-xl text-graphite text-left sm:mb-1 md:mb-3 mr-2 block`

  const ForwardSlash = () => { return <span className="text-inputGray px-[7px] flex items-center h-10">{`/`}</span> }

  return (
    <div className="pt-6 sm:px-0 pb-2">
      {!emailNotAvailable ?
        // pass the registered email to EditUnavailableDialogue if there are no formErrors for username property
        <>
          <div className="flex sm:flex-col md:flex-row md:gap-24 justify-center">
            <div className="text-left basis-1/2 sm:mb-10 md:mb-0">
              <TextInput
                type="text"
                name="username"
                errorMessageClassName="text-error text-center text-sm"
                value={stateFormFields.username}
                errorMessage={formErrors.username}
                reference={inputRefs.username}
                placeholder=""
                label="Email Address"
                labelClassName={`block ${labelClassNames}`}
                onChange={handleChange}
                autoComplete="username"
                required
                onBlur={handleBlur}
                data-testid="email_address"
              />
            </div>

            <div className="basis-1/2">
              <div className="text-left sm:mr-2 w-full">
                <label className={`block ${labelClassNames}`}>
                  {`Patient's Date of Birth `}
                  <span className="text-error">{`*`}</span>
                </label>
              </div>
              <div className="flex">
                <div className="w-4/12 px-0">
                  <TextInput
                    type="tel"
                    name="dobMonth"
                    {...dobClassNames}
                    errorMessageClassName="text-error text-center text-sm"
                    value={stateFormFields?.dobMonth}
                    errorMessage={formErrors.dobMonth}
                    reference={inputRefs.dobMonth}
                    onChange={handleChange}
                    autoComplete="bday-month"
                    placeholder="MM"
                    required
                    onBlur={handleBlur}
                    formatter={{
                      function: Formatters.dateXX,
                      onBlur: true
                    }}
                    data-testid="dob_month"
                    maxlength="2"
                  />
                </div>
                <ForwardSlash />
                <div className="w-4/12">
                  <TextInput
                    type="tel"
                    name="dobDay"
                    {...dobClassNames}
                    value={stateFormFields?.dobDay}
                    errorMessage={formErrors.dobDay}
                    reference={inputRefs.dobDay}
                    onChange={handleChange}
                    autoComplete="bday-day"
                    placeholder="DD"
                    required
                    onBlur={handleBlur}
                    formatter={{
                      function: Formatters.dateXX,
                      onBlur: true
                    }}
                    data-testid="dob_day"
                    maxlength="2"
                  />
                </div>
                <ForwardSlash />
                <div className="w-4/12">
                  <TextInput
                    type="tel"
                    name="dobYear"
                    {...dobClassNames}
                    value={stateFormFields?.dobYear}
                    errorMessage={formErrors.dobYear}
                    reference={inputRefs.dobYear}
                    onChange={handleChange}
                    autoComplete="bday-year"
                    placeholder="YYYY"
                    required
                    onBlur={handleBlur}
                    formatter={{
                      function: Formatters.dateYYYY,
                      onBlur: true
                    }}
                    data-testid="dob_year"
                    maxlength="4"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="sm:mt-4 md:mt-14 mb-12 mx-auto w-full sm:max-w-72 md:max-w-[420px] flex items-center">
            <button
              className="btn-primary mx-auto"
              onClick={handleNext}
              disabled={loading}
              data-testid="next"
            >
              {loading ? <LoadingSpinner height="2.5rem" width="2.5rem" rings={3} /> : `Next`}
            </button>
          </div>
        </>
        :
        <EditUnavailableDialogue
          isEmbedded={isEmbedded}
          userNeedsToSetPassword={internalAccountExists}
          registeredEmailUrlParam={!formErrors?.username ? `?registeredUser=${encodeURIComponent( stateFormFields?.username )}` : ``}
        />
      }
    </div>
  )
}

Qualify.propTypes = {
  isSiteOffline: PropTypes.bool,
  isEmbedded: PropTypes.bool
}