import { TextInput } from "components/forms/components"
import AddressP from "../components/AddressP"
import Alert from "../components/Alert"
import { useAddressStore } from "../state"
import { ChangeEvent, useState } from "react"
import { serializeToOriginalAddress, serializeFromVerifiedAddress } from '../serializers'
import { fetchAddressValidation, mapFootnotes } from "../components/utils"
import { useAddressStyleStore } from "../style"
import { useUpdateAddress } from "../hooks/useUpdateAddress"

export default function SubpremisePrompt() : JSX.Element {
  const [ address, setAddress ] = useAddressStore( state => [ state.address, state.setAddress ] )
  const { setCurrentView, setVerifiedAddress, setAddressWebConfirmed, setSmartyValidationMessage, addressPatientConfirmed, hideAddressConfirm } = useAddressStore()
  const [ submitLoading, setSubmitLoading ] = useState<boolean>( false )

  const [ submitError, setSubmitError ] = useState<string>( `` )

  const { styleOverrides } = useAddressStyleStore()
  const { updatePatientAddress } = useUpdateAddress( )

  const { ctaStyle } = styleOverrides

  const onContinue = async ( useWithoutApt : boolean | undefined ) => {
    setSubmitError( `` )
    const _address = {
      ...address,
      ...( useWithoutApt && {
        street2: ``
      })
    }
    setAddress( _address )

    fetchAddressValidation( serializeToOriginalAddress( address ) )
      .then( ( response ) => {
        if ( response ) {
          setSubmitError( `` )

          if ( response?.length === 0 ) return setCurrentView( `confirm` )

          const data = response[0]

          if ( data?.components ) setVerifiedAddress( serializeFromVerifiedAddress( data.delivery_line_1, data.components ) )

          // H# means we are expecting a street 2. Refer to https://www.smarty.com/docs/cloud/us-street-api for more info
          if ( data?.analysis?.footnotes?.includes( `H#` ) ) return setCurrentView( `confirm` ) // Apt number still wasn't valid, send to confirm

          const error = mapFootnotes( data.analysis.footnotes ?? `` )

          // AABB signifies a valid address and if we have a footnote that maps to an error we have setup send them to the verify step
          if ( data?.analysis?.dpv_footnotes !== `AABB` || ( data?.analysis?.footnotes && error ) ) {
            if ( data?.analysis?.footnotes ) setSmartyValidationMessage( mapFootnotes( data.analysis.footnotes ?? `` ) )
            return setCurrentView( `verify` )
          }
          setAddressWebConfirmed( true ) // This lets internal users know that the address was verified by the verification api

          if ( hideAddressConfirm ) updatePatientAddress( addressPatientConfirmed, true )
          return setCurrentView( `complete` )
        }
        setSubmitError( `Oops... we encountered an error attempting to update your address. Please try again or reach out to customer service at (866) 616-1687 if this issue persists.` )
        setCurrentView( `confirm` )
      })
      .finally( () => setSubmitLoading( false ) )
  }

  const handleAptChange = ( e: ChangeEvent<HTMLInputElement> ) => {
    setAddress({
      ...address,
      street2: e.target.value
    })
  }


  return (
    <div className="p-3 rounded-xl flex flex-col items-center gap-6 max-w-md mx-auto">
      <Alert text={`Our records indicate this address might require an apt/unit number`} />
      <AddressP address={address} className="text-center" />
      <div className="max-w-xs">
        <TextInput
          name="apt"
          label="Add Apt/Unit Number"
          labelClassName="text-sm font-light pb-1"
          required
          value={address.street2}
          onChange={handleAptChange}
        />
      </div>
      { submitError && <p className="text-error text-center">{submitError}</p> }
      <div className="w-full flex items-center justify-center">
        {
          address?.street2?.length > 0 ? (
            <button className="btn btn-primary" onClick={() => onContinue( false )} disabled={submitLoading}>{`Continue`}</button>
          ) : (
            <p className={ctaStyle} onClick={() => onContinue( true )}>{`Continue without Apt/Unit`}</p>
          )
        }
      </div>
    </div>
  )
}