import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client'

import { preLoginLocationVar } from 'apollo'
import { generateCustomerTokenMutation } from 'graphql/mutations'
import { getRedirectable } from 'modules/auth/redirectable'

import { FormButton, PasswordInput } from 'components/forms/components'
import LoadingSpinner from 'components/LoadingSpinner'
import { EmailInput, useInputState, useValidInputs } from './input'
import { reportToSentry } from 'utils/reportToSentry'
import { LOGIN_LEGACY_PATH, MY_ACCOUNT_PATH } from 'routes'
import { useTokenStore } from 'stores/tokenStore'
import { isEmailAvailableQuery } from 'graphql/queries'

const LoginForm = ({ setError } : { setError : React.Dispatch<React.SetStateAction<string>>}) => {
  const { email } = useInputState()
  const { isValidEmail } = useValidInputs()

  const [ password, setPassword ] = useState( `` )
  const [ loading, setLoading ] = useState( false )

  const navigate = useNavigate()
  const locationState = useReactiveVar( preLoginLocationVar )

  const { setCustomerToken } = useTokenStore()

  const [ checkEmailAvailability ] = useLazyQuery(
    isEmailAvailableQuery, {
      variables: {
        email
      },
      onError: ( ( error ) => {
        reportToSentry( new Error ( `isEmailAvailableQuery -> Login`, {
          cause: error
        }) )
      })
    })

  const [ login ] = useMutation( generateCustomerTokenMutation, {
    fetchPolicy: `no-cache`, // Prevent caching user login creds
    variables: {
      email,
      password
    },
    onCompleted: ({ generateCustomerToken }) => {
      if ( generateCustomerToken?.token ) {
        setCustomerToken( generateCustomerToken.token )

        const { from } = locationState || {
          from: {
            pathname: MY_ACCOUNT_PATH,
            search: window.location.search
          }
        }
        const redirectable = getRedirectable( from )

        return navigate( redirectable?.redirectLocation ?? MY_ACCOUNT_PATH, {
          state: locationState,
          replace: true
        })
      }

      setError( `There was an error logging in.` )
      setLoading( false )
    },
    onError: ( data ) => {

      const { graphQLErrors: errors } = data

      // we want to display something different if there is an error of a certain message
      const signInAuthError = errors?.find( error => {
        return error.message === `The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.`
      })

      if ( !signInAuthError ){
        reportToSentry( new Error( `non auth related generateCustomerTokenMutation error in LoginForm`, {
          cause: data
        }) )
      }

      setError( signInAuthError ? `The username/password combination was not found` : errors.map( errorObject => { return errorObject.message }).join( ` ` ) )
      setLoading( false )
    }
  })

  const handleSubmit = async () => {
    if ( !isValidEmail ) return setError( `Invalid email address or password.` )
    setLoading( true )
    const emailAvailableResponse = await checkEmailAvailability().catch( () => {
      return setError( `We had an issue finding your account.` )
    })

    if ( emailAvailableResponse?.error ) {
      reportToSentry( `Login - Email Available M2 Request Failed`, () => {
        JSON.stringify( emailAvailableResponse?.error )
      })

      return setError( `We had an issue finding your account.` )
    }

    if ( !emailAvailableResponse?.data?.isEmailAvailable.is_email_available ) login()
    else navigate( LOGIN_LEGACY_PATH )
  }

  if ( loading ) {
    return (
      <div className="my-8">
        <p className="mb-2">{`Logging in...`}</p>
        <LoadingSpinner />
      </div>
    )
  }

  return (
    <form onSubmit={handleSubmit} className="mx-auto flex flex-col items-center md:items-start w-full">
      <p className="text-graphite text-center my-5 font-light text-lg">{`If you have an account, log in with your email address.`}</p>

      <div className="mb-5 w-full">
        <EmailInput required allowReadonly={false} />
        <PasswordInput
          name="password" label="Password"
          value={password}
          onChange={( e ) => { setPassword( e.currentTarget.value ) }}
          required
          autoComplete="current-password"
        />
      </div>
      <FormButton className="sm:mr-0 mt-2 mb-8 btn-primary">{`Login`}</FormButton>

      <Link to="/request-password-reset" className="a underline decoration-deepSleepBlue mt-4">
        <p className="mx-auto w-full text-center text-lg font-light">{`Forgot your Password?`}</p>
      </Link>
    </form>
  )
}

export default LoginForm
