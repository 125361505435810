import LoadingSpinner from 'components/LoadingSpinner'
import { useEffect } from 'react'
import { useTokenStore } from 'stores'

function ResupplyRedirect() {
  const { customerToken } = useTokenStore()

  useEffect( () => {
    window.location.href = `${process.env.REACT_APP_ALOKAI_RESUPPLY_URL}/login?mct=${customerToken}`
  }, [] )

  return <div className="m-10"><LoadingSpinner /></div>

}

export default ResupplyRedirect