import { MigrationLoginForm } from "components/auth"
import PageNavigator from '../../components/pageNavigator'
import { ResupplyGateway } from '../resupplyGateway'
import React from 'react'

export default function LegacyLoginScreen() : JSX.Element {
  const [ patientData, setPatientData ] = React.useState<any>({})
  const resupplyGatewayPage= `/login-legacy/resupply-gateway`
  const handleSetPatientData = ( data: any ) => {
    setPatientData( data )
  }

  return (
    <div className="max-w-2xl px-8 mx-auto flex flex-col items-center justify-center my-10">
      <PageNavigator
        pageNames={[ `/login-legacy`, resupplyGatewayPage ]}
        childSharedProps={{
          patientData,
          handleSetPatientData
        }}
      >
        <MigrationLoginForm />
        <ResupplyGateway />
      </PageNavigator>
    </div>
  )
}